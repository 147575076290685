<template>
    <uw-content title="产品库存">

        <!-- 表格 -->
        <uw-table>

            <!-- 检索 -->
            <template slot="search">
                <ims-warehouse-select  v-model="search.warehoser_id" @onChange="TableSearch()" />
            </template>

            <!-- 按钮 -->
            <template slot="button">
                <el-button type="primary" size="mini" @click="$refs.xTable.openExport()" >导出</el-button>
            </template>

            <!-- 表数据 -->
            <template slot="data">
                <vxe-table
                    ref="xTable"
                    :data="table"
                    :loading="loading"
                    :export-config="{}"
                    :print-config="{}"
                    :seq-config="{startIndex: page.size * (page.current - 1)}"
                    height="100%">

                    <vxe-column min-width="80" field="warehouse.name" title="所属仓库"></vxe-column>

                    <!-- 产品信息 -->
                    <vxe-column min-width="120" title="产品名称">
                        <template #default="{ row }">
                            <span :style="{ color: '#0027ff', cursor: 'pointer' }" @click="$refs.productDetails.Open(row.product.id)">{{ row.product.name }}</span>
                        </template>
                    </vxe-column>
                    <vxe-column min-width="120" title="供应商">
                        <template #default="{ row }">
                            <span :style="{ color: '#0027ff', cursor: 'pointer' }" @click="$refs.supplierDetails.Open(row.product.supplier.id)">{{ row.product.supplier.name }}</span>
                        </template>
                    </vxe-column>

                    <!-- 规格参数 -->
                    <vxe-column min-width="250" title="规格参数">
                        <template #default="{ row }">
                            {{ row.product.parameter?.toString() }}
                        </template>
                    </vxe-column>

                    <!-- 产品类别 / 产品品牌 / 计量单位 / 库存量 -->
                    <vxe-column min-width="80" field="product.type.name" title="产品类别"></vxe-column>
                    <vxe-column min-width="80" field="product.brand" title="产品品牌"></vxe-column>
                    <vxe-column min-width="80" field="product.unit" title="计量单位"></vxe-column>
                    <vxe-column min-width="80" field="num" title="库存量"></vxe-column>
                    
                </vxe-table>
            </template>

            <!-- 分页 -->
            <template slot="pager">
                <vxe-pager :total="page.total" :page-size.sync="page.size" :current-page.sync="page.current" @page-change="TableSearch()"></vxe-pager>
            </template>

        </uw-table>

        <!-- 组件 -->
        <ims-product-drawer ref="productDetails" @onChange="TableSearch" />
        <ims-supplier-drawer ref="supplierDetails" @onChange="TableSearch" />

    </uw-content>
</template>

<script>
export default {
    data () {
        return {
            // 窗口
            loading: false,

            table: [],

            // 分页
            page: {
                total: 0,
                size: 50,
                current: 1,
            },

            // 检索
            search: {
                number: null,
            },

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            },
        }
    },

    mounted () {
        this.TableSearch()
    },

    methods: {

        // 供应商 - 查询
        TableSearch() {
            // 切换加载状态
            this.loading = true

            this.$http.post(
                '/9api/ims/warehouse-storage/search',
                {
                    page: this.page,
                    search: this.search,
                    order: this.order,
                    append: ['product', 'warehouse']
                }
            ).then(rps => {
                
                // 更新数据
                this.page.total = rps.data.total
                this.table = rps.data.body

                // 页码溢出
                if (this.table.length == 0 && this.page.current != 1) {
                    this.page.current = 1
                    this.TableSearch()
                }

                // 切换加载状态
                this.loading = false
            })
        }
    }

}
</script>